<template>
    <phi-page :loading="app.api.isLoading" color="#f2f2f2">
		<div slot="toolbar">
            <mu-icon-button @click="$router.go(-1)" icon="arrow_back" />
			<h1>{{ $t("noun.postTypes") }}</h1>
		</div>

        <div class="phi-post-types">
            <div class="phi-card">
                <div class="type" v-for="(type, key) in types" :key="type.singular">

                    <router-link :to="`/post/types/${encodeURIComponent(type.singular)}`" class="phi-media">
                        <div class="phi-media-figure">
                            <img v-if="type.icon" :src="type.icon" alt="type.singular" />
                            <mu-icon v-else value="insert_drive_file" :size="40" />
                        </div>
                        <h3 class="phi-media-body">{{ $t(type.singular) }}</h3>
                    </router-link>

                </div>

                <phi-type-editor v-model="newType" :label="$t('action.createNewType')" @input="saveNewType"></phi-type-editor>
            </div>

        </div>
    </phi-page>
</template>

<script>
import app from '../../store/app.js';
import PhiTypeEditor from '../../components/Phi/Type/Editor.vue';

export default {

    components: {
        PhiTypeEditor
    },

    data() {
        return {
            app,
            types: [],
            newType: {
                singular: null,
                plural: null,
                gender: 1,
                color: null,
                icon: null
            },
            isCreating: false
        };
    },

    methods: {
        load() {
            app.api
                .get("/types/bulletin")
                .then(response => this.types = response);
        },

        saveNewType() {
            app.api
                .post("/types/bulletin", this.newType)
                .then(createdType => {
                    this.$router.push(`/post/types/${encodeURIComponent(createdType.singular)}`);
                });
        }
    },

    beforeMount() {
        this.load();
    }
}
</script>

<style lang="scss">
.phi-post-types {
    .phi-media {
        align-items: center;
    }

    h3.phi-media-body {
        font-size: 20px;
    }

    .new-type {
        align-items: center;
    }
}
</style>